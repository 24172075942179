import { Dictionary } from '@ngrx/entity';

export enum QuotaType {
  SEATS = 'Products\\Quotas\\Seats',
  DEPARTMENTS = 'Products\\Quotas\\Departments',
  TEAMS = 'Products\\Quotas\\Teams',
  HIDDEN_TEAMS = 'Products\\Quotas\\HiddenTeams',
  FLEXPOOL_TEAMS = 'Products\\Quotas\\FlexpoolTeams',
  INTEGRATIONS = 'Products\\Quotas\\Integrations',
  SHIFT_TASKS = 'Products\\Quotas\\ShiftsOfTypeTask',
  TIME_OFF_BALANCES = 'Products\\Quotas\\TimeOffBalances',
  ACTIVE_TIME_OFF_BALANCES = 'Products\\Quotas\\ActiveTimeOffBalances',
  ABSENCE_POLICIES = 'Products\\Quotas\\AbsencePolicies',
  RATE_CARDS_WITH_MULTIPLE_TIME_BLOCKS = 'Products\\Quotas\\RateCardsWithMultipleTimeBlocks',
  RATE_CARDS_WITH_TIME_SURCHARGES = 'Products\\Quotas\\RatecardsWithTimeSurcharge',
  HOLIDAY_GROUPS = 'Products\\Quotas\\HolidayGroups',
}

// TODO this const is needed because backend had to store an unlimited quota as 9999999
// instead of something more sensible like -1 for backwards compatibility reasons.
// We need to account for that '9999999 === unlimited' quirk in some places.
export const UNLIMITED_QUOTA = 9999999;

export enum PlanType {
  FREE = 'free',
  BASIC = 'basic',
  //Techdept an entry for starter had to be maintained for backwards compatabillity issues
  //Remove with tmp_rename_starter_to_basic (SHB-8838)
  STARTER = 'starter',
  EARLY_ADOPTER = 'early_adopter',
  PREMIUM = 'premium',
}

export const transformedPlanTypeLabels = {
  [PlanType.FREE]: 'Free',
  [PlanType.BASIC]: 'Basic',
  //Techdept an entry for starter had to be maintained for backwards compatabillity issues
  //Remove with tmp_rename_starter_to_basic (SHB-8838)
  [PlanType.STARTER]: 'Basic',
  [PlanType.EARLY_ADOPTER]: 'Early Adopter',
  [PlanType.PREMIUM]: 'Premium',
};

export interface SubscriptionModel {
  id: string;
  price: number;
  display_price: string;
  display_year_price: string;
  min_price: string;
  display_min_price: string;
  min_order_quantity: number;
  plan: PlanModel;
  quotaReached?: PlanQuotaModel[];
  visible: boolean;
  currency_code: string;
  invoice_period: InvoicePeriodType;
  name?: string;
  next_billing_date: string;
  scheduled_subscription?: PlanType;
  trial_granted: boolean;
}

export interface SelectedSubscriptionModel {
  subscription: SubscriptionModel;
  integrationPlus?: boolean;
  hasCustomSubscription?: boolean;
  invoicePeriod: InvoicePeriodType;
}

export enum InvoicePeriodType {
  MONTH = 'month',
  YEAR = 'year',
}

export interface PlanModel {
  name: string;
  quotas: Dictionary<PlanQuotaModel>;
  type: PlanType;
  weight: number;
}

export interface PlanQuotaModel {
  name: QuotaType;
  limit: number;
  usage: number;
  type: QuotaType;
}

export enum PaymentMethodType {
  NONE = 'No payment details',
  CREDIT_CARD = 'Credit card',
  DEBIT = 'Automatic debit',
  TRANSFER = 'Transfer',
}

export const PlanTypeWeight = {
  [PlanType.FREE]: 0,
  [PlanType.BASIC]: 1,
  //Techdept an entry for starter had to be maintained for backwards compatabillity issues
  //Remove with tmp_rename_starter_to_basic (SHB-8838)
  [PlanType.STARTER]: 1,
  [PlanType.EARLY_ADOPTER]: 2,
  [PlanType.PREMIUM]: 3,
};

export enum SubscriptionCheckType {
  AT_LEAST,
  EXACTLY,
  AT_MOST,
}

export interface SubscriptionEstimatesModel {
  unit_amount: number;
  quantity: number;
  next_billing_date: string;
  sub_total: number;
  total: number;
  taxes: EstimateTaxModel[];
  tax_amount: number;
  currency: string;
  line_items: EstimateItemModel[];
  currency_symbol: string;
}

export interface EstimateTaxModel {
  object: string;
  name: string;
  description: string;
  amount: number;
}

export interface EstimateItemModel {
  entity_type: string;
  description: string;
  quantity: number;
  unit_amount: number;
  amount: number;
  discount_amount: number;
}
